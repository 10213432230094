<template>
  <div>
    <GmapMap :zoom="zoom" :center="center" style="width: 100%; height: 500px" :options="options">
      <DirectionsRenderer 
        travelMode="DRIVING"
        :origin="startLocation"
        :destination="endLocation"
        :waypoints="waypoints"
        :optimizeWaypoints="true"
      />
      <GmapMarker v-for="(m, index) in markers" :position="m.position" @click="center = m.position" :label="m.label" :key="index" :clickable="true"
      :icon="m.icon"></GmapMarker>
    </GmapMap>
    <br>

<div id="table"></div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

           <!-- Referesh -->
          <b-col cols="12"
          md="3">
             <b-button
                variant="primary"
                @click="refreshData()"
              >
               <feather-icon icon="RefreshCcwIcon" />
              </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refRidersOnlineTable"
        class="position-relative"
        :items="ridersOnline"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

      <template #cell(lastSeen)="data">
        <p>
          <!-- {{data.item.updatedAt}} -->
          <!-- {{formatDate(data.item.updatedAt, {hour: 'numeric', minute: 'numeric', second: 'numeric'})}} -->
          {{moment(data.item.updatedAt).startOf("minute").fromNow()}}
        </p>
      </template>
       
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="button"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item-button @click="showOnMap(data.item)">
              <feather-icon icon="MapIcon" />
              <span>View on map</span>
            </b-dropdown-item-button>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRidersOnline"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BCardText,
  BBadge, BDropdown, BDropdownItem, BDropdownItemButton, BPagination, BTabs, BTab,BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import { formatDate, dateDiff } from '@core/utils/filter'
import useOnlineRider from './useOnlineRider'
import Ripple from 'vue-ripple-directive'
import DirectionsRenderer from '@/@core/components/directions-renderer'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BPagination,
    BCard,
    BTabs,
    BTab,
    BFormCheckbox,
    BCardText,
    DirectionsRenderer,

    vSelect,
  },
  directives: {
    Ripple,
  },
   data() {
    return {
      zoom: 18,
      center: {lat: 9.072264, lng: 7.491302},
      options: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: true,
        disableDefaultUi: false,
        gestureHandling: 'cooperative'
      },
      startLocation:  null,
      endLocation:  null,
      waypoints: [],
      markers: [],
      shape: {
        coords: [10, 10, 10, 15, 15, 15, 15, 100],
        type: 'poly'
      },
    }
  },
  methods: {
    showOnMap(onlineRider) {
      this.removeMarker(0)
      this.addMarker(parseFloat(onlineRider.longitude), parseFloat(onlineRider.latitude))
      this.center = {lat: parseFloat(onlineRider.longitude), lng: parseFloat(onlineRider.latitude)}
    
    // smooth scroll to map
    const rootEle = document.documentElement
      rootEle.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
     alert(item) {
      // eslint-disable-next-line no-alert
      alert(`this is ${JSON.stringify(item)}`)
    },
    addMarker(lat, lng) {
      const newMarker = {
        position: { lat, lng },
        draggable: false,
        visible: true,
        icon: require('@/assets/images/blink2.gif')
      }
      this.markers.push(newMarker)
    },
    removeMarker(index) {
      this.markers.splice(index, 1)
    },
  },
  mounted() {
    const el = this.$el.querySelector("#table");

        if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({behavior: 'smooth'});
        }
  },
  setup() {

    onMounted(() => {
      fetchRidersOnline()
    })

    const {
      fetchRidersOnline,
      ridersOnline,
      tableColumns,
      perPage,
      currentPage,
      totalRidersOnline,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRidersOnlineTable,
      refetchData,
      refreshData,
    } = useOnlineRider()

    return {
      ridersOnline,
      tableColumns,
      perPage,
      currentPage,
      totalRidersOnline,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRidersOnlineTable,
      refetchData,
      refreshData,

      // Filter
      formatDate,
      dateDiff,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.vue2leaflet-map{
  &.leaflet-container{
    height: 350px;
  }
}
</style>
